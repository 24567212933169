<template>
  <v-card>
    <v-row class="justify-space-around">
      <v-col class="col-12 pb-0">
        <a href="https://cpa-net.jp">
          <v-img
            class="mx-10"
            src="@/assets/cpa/kakomon_banner_2024.jpg"
            eager="eager"
            contain="contain"
          /></a>
        <v-btn
          class="my-0 mr-10 float-right text-decoration-none"
          text="text"
          x-small="x-small"
          color="blue darken-1"
          to="/newscpa"
        >
          >> 協賛のお知らせ
        </v-btn>
      </v-col>
      <v-col class="col-12 pt-0">
        <v-img
          class="mt-3 mx-10"
          src="@/assets/kaikei.png"
          eager="eager"
          contain="contain"
        />
      </v-col>
    </v-row>
    <v-row class="justify-space-around">
      <!-- v-btn.ma-5(color="orange" large dark min-width="300px" to="/select_questions/honban") 過去問-->
      <v-btn
        class="ma-5"
        color="orange"
        large="large"
        dark="dark"
        min-width="300px"
        to="/select_questions"
      >
        一問一答
      </v-btn>
    </v-row>
    <v-row class="justify-space-around my-3">
      <v-btn
        outlined="outlined"
        color="green"
        to="/statistics"
      >
        統計情報を見る
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
    <v-divider />
    <news />
    <v-divider />
    <Carousel />
    <v-divider />
    <v-card-title class="mx-3 mb-0 pb-0">
      サンプル問題
    </v-card-title>
    <v-row class="ma-3 justify-center">
      <SampleQuestion />
    </v-row>
  </v-card>
</template>

<script>
import SampleQuestion from "../components/Top/SampleQuestion";
import News from "../components/Top/News";
import Carousel from "@/components/Ads/Carousel.vue";

export default {
  name: "Top",
  components: { Carousel, News, SampleQuestion },
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>
