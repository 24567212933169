<template>
  <v-card
    class="pa-3"
    flat="flat"
  >
    <v-card-title class="py-1">
      ご協賛一覧
    </v-card-title>
    <v-row class="ma-3">
      <v-carousel
        v-model="model"
        show-arrows-on-hover="show-arrows-on-hover"
        height="200"
        cycle="cycle"
      >
        <v-carousel-item
          v-for="(ad, i) in ads"
          :key="i"
        >
          <a
            :href="ad.url"
            noopener="true"
            target="_blank"
          >
            <v-img
              :src="ad.image"
              contain="contain"
              eager="eager"
              max-height="150px"
            /></a>
        </v-carousel-item>
      </v-carousel>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      model: 0,
      ads: [
        {
          image: require('../../assets/logos/cpa.png'),
          url : 'https://cpa-net.jp',
        },
        {image: require('../../assets/logos/bdo.png'),
          url: 'https://recruit.bdo.or.jp/'
        },
        {image: require('../../assets/logos/growth.png'),
          url: 'https://www.growth-audit.jp/'
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>
