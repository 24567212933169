<template>
  <v-card
    class="pa-3"
    flat="flat"
  >
    <v-card-title class="py-1">
      お知らせ一覧
    </v-card-title>
    <v-row class="ma-3">
      <v-expansion-panels
        v-model="panel"
        multiple="multiple"
        focusable="focusable"
        accordion="accordion"
      >
        <v-expansion-panel
          v-for="item in items"
          :key="item.date + item.title"
        >
          <v-expansion-panel-header
            expand-icon="mdi-menu-down"
            color="grey lighten-3"
          >
            <template>
              <v-row class="no-gutters align-center">
                <span class="mr-6">{{ item.title }}</span>
                <div class="text--secondary caption">
                  <i class="mdi mdi-update" />{{ item.date }}
                </div>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->

            <v-card-text
              class="ma-0 pa-0"
              v-html="item.content"
            />
            <!-- eslint-enable vue/no-v-text-v-html-on-component vue/no-v-html -->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-card>
</template>

<script>
import Items from '../../assets/NewsReleases.json';

export default {
  name: "News",
  data() {
    return {
      panel: [0],
      items: Items.slice(0, 5)
    }
  },
}
</script>

<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 16px 24px;
}

</style>
